import { useEffect } from 'react';
import axios from 'axios';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import { getCodeFromUrlParams, getUserFromBrowser, getUserFromAuthServiceSession, getSubscriptionFromBrowser, getUserFromGoogleGivenCode } from '../helpers/AuthWrapperHelper';
import { useLocation } from 'react-router-dom';
import { fetchActiveSubscriptions, listenToUpdatesInSubscriptionsCollection } from '../components/firebaseHelpers/listenToUpdatesInSubscriptionsCollection';
import { reFetchUser, updateUser } from '../components/firebaseHelpers/usersCollection';
//import { fetchUserPaymentInfo, updateUserPayment } from '../components/firebaseHelpers/payments';
//import { usePaymentWrapper } from './PaymentWrapper';
import { getStripeCheckoutRetrieveSessionUrl } from '../components/constants/helpers';
import { listenForUpdatesInPaymentsCollection } from '../components/firebaseHelpers/listenForUpdatesInPaymentsCollection';
import { listenForUpdatesInMeterCollection } from '../components/firebaseHelpers/listenForUpdatesInMeteringColelction';
import { updateStateWithPaymentInfo } from '../components/firebaseHelpers/paymentsCollection';
import { listenToUpdatesInUsersCollection } from '../components/firebaseHelpers/listenToUpdatesInUsersCollection';

export const useAuthWrapper = () => {
  const [{ user, subscription }, dispatch] = useStateValue();
  const location = useLocation();
  //usePaymentWrapper();

  // useEffect(()=>{
  //   console.log(`User: ${JSON.stringify(user)}`);
  //   const getPaymentInfo = async () => {
  //     const paymentInfo = await fetchUserPaymentInfo(user);
  //     if(!!paymentInfo){
  //       dispatch({ type: actionTypes.SET_PAYMENT_INFO, paymentInfo: paymentInfo });
  //     }
  //   }
  //   if(!!user && !!user.sub){
  //     getPaymentInfo(user);
  //   }
    
  // },[user]);


  useEffect(() => {
    
   // axios.defaults.withCredentials = true;

    const configurePaymentStateOnPageMount = async () => {
  
      if(!user){
        return;
      }

      //fetchUserPaymentInfo
      // const paymentInfo = await fetchUserPaymentInfo(user);
      // if(!!paymentInfo){
      //   console.log("Payment info exists");
      // } else{
      //   console.log("No Payment info exists in DB");
      // }
      
      // const urlParams = new URLSearchParams(location.search);
      // const session_id = urlParams.get("session_id");
      // const session = await axios.post(getStripeCheckoutRetrieveSessionUrl(),{sessionId:session_id});
      // await updateUserPayment(session, user);
    }

    // const test = async (user)=>{
    //     const urlParams = new URLSearchParams(location.search);
    //     const session_id = urlParams.get("session_id");
    //     console.log("Session id does exists");
    //     console.log(`Session id is: ${session_id}`);
    //     console.log("Try to get payment info from stripe");
    //     const session = await axios.post(getStripeCheckoutRetrieveSessionUrl(),{sessionId:session_id});
    //     console.log(`Stripe responded with: ${JSON.stringify(session.data.session)}`);
    //     console.log("Attempting to store this object in firebase");
    //     await updateUserPayment(session.data.session, user);
    //     console.log("check DB");
    // }

    const configureAppOnPageMount = async () => {
      if(!!user) { 
        return;
      }

      const userFromBrowser = getUserFromBrowser(); 
      if(!!userFromBrowser){
        //listenToUpdatesInUsersCollection(userFromBrowser.sub, dispatch);
     //   await reFetchUser(userFromBrowser);
        dispatch({ type: actionTypes.SET_USER, user: userFromBrowser });
        
      }

      const code = getCodeFromUrlParams(location);
      if(!!code){
        const userFromGoogle = await getUserFromGoogleGivenCode(code); 
        localStorage.setItem("user", JSON.stringify(userFromGoogle)); 
        dispatch({ type: actionTypes.SET_USER, user: userFromGoogle });
        updateUser(userFromGoogle);
      }
      
      // if(!!user){
      //   listenToUpdatesInUsersCollection(user.sub, dispatch);
      // }
      
    }
//
    const setAppStateOnPageMount = async () => {
      
      //if a user or active subcription exists in state return
      if (!!user || !!subscription) {  
        return;
      }

      const code = getCodeFromUrlParams(location); //check if there is code in the urlparams 

      if (!!code) {  //there is some info in the url params => user has re-authenticated
        const userFromGoogle = await getUserFromGoogleGivenCode(code); //fetch user from firestore using newly generated code
        const userSubscription = await fetchActiveSubscriptions(userFromGoogle.sub); //fetch for any subscriptions
        
        localStorage.setItem("user", JSON.stringify(userFromGoogle));
        dispatch({type: actionTypes.SET_USER, user: userFromGoogle});
        //start listening to subscription events
        listenToUpdatesInSubscriptionsCollection(userFromGoogle.sub, dispatch);
        
        if(!!userSubscription){
          localStorage.setItem("subscription", JSON.stringify(userSubscription));
          dispatch({type: actionTypes.SET_STRIPE_SUBSCRIPTION, subscription: userSubscription});
        }
        return;
      }

      //if a user does not exist in state, check if user data lives in browser
      const userFromBrowser = getUserFromBrowser();
      //if user lives in browser use that to log them in app
      if(!!userFromBrowser){
        dispatch({ type: actionTypes.SET_USER, user: userFromBrowser });
        //it is already in local browser storage

        //start listening for changes in subscription collection associated with this user
        listenToUpdatesInSubscriptionsCollection(userFromBrowser, dispatch); //
      }

      //if there is no subscription in state, check if subscription data lives in browser
      const subscriptionFromBrowser = getSubscriptionFromBrowser();
      //if subscription data lives in browser store this information in app state
      if(!!subscriptionFromBrowser){
        dispatch({type: actionTypes.SET_STRIPE_SUBSCRIPTION, subscriptionFromBrowser});
      }      
    }
    //setAppStateOnPageMount();
   
    //configureAppOnPageMount(); --main;
   
    // configurePaymentStateOnPageMount();
    
  }, []);

  useEffect(()=>{

    console.log("User has been set");
    const getPaymentFromState = async (user, dispatch)=>{
      await updateStateWithPaymentInfo(user, dispatch);
    }
    if(!!user){
     // getPaymentFromState(user, dispatch);
      
      console.log(`Listen for updates to payments collection`);
      //listenToUpdatesInUsersCollection(user.sub, dispatch);

      listenForUpdatesInPaymentsCollection(user.sub, dispatch);  
     // listenToUpdatesInUsersCollection(user.sub, dispatch);
      listenForUpdatesInMeterCollection(user.sub, dispatch); //start listening for meter updates 
    }
  },[user]) //user has been set by browser or code

  return null;
};

// async function onStart(user, subscription,  dispatch) {




//   const subscription = await fetchActiveSubscriptions(user.sub);
//   if (!!subscription) {
//     localStorage.setItem("subscription", JSON.stringify(subscription));
//     dispatch({ type: actionTypes.SET_STRIPE_SUBSCRIPTION, subscription: subscription });
//   }
//   listenToUpdatesInSubscriptionsCollection(user.sub, dispatch);
//   dispatch({ type: actionTypes.SET_USER, user: user });
//   dispatch({type: actionTypes.SET_LOGIN_LOADING, loginLoading: false});  
// }


// import React, { useEffect } from 'react';
// import axios from 'axios';
// import { useStateValue } from '../statemanagement/StateProvider';
// import { actionTypes } from '../statemanagement/reducer';
// import { getCodeFromUrlParams, getUserFromAuthServiceSession, getUserFromGoogleGivenCode } from '../helpers/AuthWrapperHelper';
// import { useLocation } from 'react-router-dom';
// import { fetchActiveSubscriptions, listenToUpdatesInSubscriptionsCollection } from '../components/firebaseHelpers/listenToUpdatesInSubscriptionsCollection';

// //wraps the entire app
// //can be used for setup on load
// export const AuthWrapper = () => {

//   axios.defaults.withCredentials = true;
//   const [{ user }, dispatch] = useStateValue();
//   const location = useLocation();

//   useEffect(() => {
    
//     const attemptToLoginUserOnMount = async () => {
//       if (!!user) {
//         console.log("User has already logged in");
//         return;
//       }
//       //user does not exist in local state
//       const user = await getUserFromAuthServiceSession();//nothing has to be passed in since user is held in backend session store
//       if(!!user){
//         await onStart(user, dispatch);
//         return;
//       }
      

//       const code = getCodeFromUrlParams(location);
//       if (!!code) {
//         const user = await getUserFromGoogleGivenCode(code);
//         if(!!user){
//           await onStart(user, dispatch);
//           return;
//         }
        
//       }
//     }
//     console.log(`User: ${user}`);
//     attemptToLoginUserOnMount(user);
//   }, []);

//   return null;
// }

// async function onStart(user, dispatch) {
//   const subscription = await fetchActiveSubscriptions(user.sub);//fetch the subscription associated with the user sub
//   if (!!subscription) {
//     dispatch({ type: actionTypes.SET_PAYPAL_SUBSCRIPTION, subscription: subscription });
//   }
//   listenToUpdatesInSubscriptionsCollection(user.sub, dispatch);//listen for subscription updates, update app state 
//   dispatch({ type: actionTypes.SET_USER, user: user });//update user to local state 
// }
