import { doc, onSnapshot, collection } from "firebase/firestore"; //
import { db } from "../../config/firebase"; //connect to firestore db
import { actionTypes } from "../../statemanagement/reducer";

const usersCollection = collection(db, "users");

export const listenToUpdatesInUsersCollection = (userId, dispatch) => {
  console.log("listenToUpdatesInUsersCollection")
  const userRef = doc(usersCollection, userId);

  console.log("Call OnSnapshot");
  const unsubscribe = onSnapshot(userRef, (userDoc) => {
    console.log("Users Collection has been modified");
    console.log(JSON.stringify(userDoc.data()));
    if (userDoc.exists()) {
      console.log("User exists in DB");
      const userData = userDoc.data();
      console.log("Setting user from DB to local state");
    //  dispatch({type: actionTypes.SET_USER, user: userData});
    } else {
      console.log("User document doesn't exist");
      //dispatch({type: actionTypes.SET_USER, user: null});
    }
  });

  return unsubscribe; // Return a function to stop listening for changes when needed
};
