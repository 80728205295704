import { db } from "../../config/firebase";
import {collection, doc, setDoc, getDoc, deleteDoc} from "firebase/firestore";

export const upsertSubscription = async (data, user) => {

    const subscriptionsCollection = collection(db, "subscriptions");
    const subscriptionRef = doc(subscriptionsCollection, user.sub);

    try{
        //check if doc/subscription exists

        const subscriptionDoc = await getDoc(subscriptionRef);
        if(subscriptionDoc.exists()){
            console.log("Updated user subscription info");
            await setDoc(subscriptionRef, {...data, sub: user.sub}, {merge: true});
        } else{
            console.log("Added subscription to DB");
            await setDoc(subscriptionRef, {...data, sub: user.sub});
        }
        return true;
    } catch(error){
        console.error(`${error}`);
        return false;
    }
}

export const deleteSubscription = async (user) => {
    const subscriptionsCollection = collection(db, "subscriptions");
    const subscriptionRef = doc(subscriptionsCollection, user.sub);
    try{
        const subscriptionDoc = await getDoc(subscriptionRef);
        if(subscriptionDoc.exists()){
            await deleteDoc(subscriptionRef);
            return true;
        } else{
            console.log("Subscription was not found");
            return false;
        }

    } catch (error){
        console.error(`Failed to delete subscription in DB: ${error}`);
        return false;
    }
}



// export const getSubscriptionById = async (userId) => {

//     const subscriptionsCollection = collection(db, "subscriptions");
//     const subscriptionRef = doc(subscriptionsCollection, user.sub);
//   const userRef = doc(usersCollection, userId);

//   try {
//     const userDoc = await getDoc(userRef);

//     if (userDoc.exists()) {
//       const userData = userDoc.data();
//       console.log("Retrieved user:", userData);
//       return userData;
//     } else {
//       console.log("User not found");
//       return null;
//     }
//   } catch (error) {
//     console.error("Error retrieving user:", error);
//     return null;
//   }
// };