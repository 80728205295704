import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HttpsIcon from '@mui/icons-material/Https';
import axios from "axios";
import { StripeCheckoutComponent } from '../components/StripeCheckout/StripeCheckoutComponent';

export default function BasicCard({navigateHome, login, user, subscription, paymentInfo, meter}) {

    const bull = (
        <Box
          component="span"
          sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
          •
        </Box>
      );
  return (
    <Card sx={{ minWidth: 275, background:"whitesmoke", border:"none", boxShadow:"none" }}>
       
      <CardContent>
      <CardActions>
        
        {/* {!!user && !subscription && <PayPalComponent style={{paddingTop:"5%", paddingBottom:"5%"}} />} */}
        
      </CardActions> 
        <Typography sx={{ fontSize: 30, textAlign:"center" }} color="text.primary" gutterBottom>
          WriteChat.ai
        </Typography> 
        <Typography sx={{ fontSize: 20, textAlign:"center" }} color="text.secondary" gutterBottom>
          AI Accessible for everyone
        </Typography> 
        {/* <Typography sx={{ fontSize: 11, textAlign:"center" }} variant="h5" component="div">
             <i>AI accessible for all</i>
        </Typography> */}
        <Typography sx={{ fontSize: 24, textAlign:"center" }} variant="h5" component="div">
            ₹5 only ($0.06) (Experimental pricing till 11/12/2023) 
        </Typography> <br/>
         <Typography sx={{ fontSize: 12, textAlign:"center" }} variant="h5" component="div">
            Unlimited refinements, 5 new generations
        </Typography>
      
       {!!user && (!!paymentInfo && paymentInfo.payment_status == "paid") && <Button className="get_started" style={{margin:"auto"}} onClick={navigateHome}>Start using WriteChat</Button>}
       {/* {!!user && (!!paymentInfo && paymentInfo.payment_status == "paid") && <p>${meter}/50 generations used</p>} */}
        {/* <br /> */}
       <><br /></>
       {!user && <Button className="sign_in_button" style={{margin:"auto"}} onClick={(_) => {
         window.gtag('event', 'LoginInitiated');
         login();
        }}>
            Sign in with Google to Continue <HttpsIcon style={{ paddingLeft: "9px", fontSize: "x-large", paddingRight: "0px", paddingTop: "0px" }} />
        </Button>}
        {!!user && (!paymentInfo || paymentInfo.payment_status != "paid") && <StripeCheckoutComponent user={user} />} 
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Developed by ex-Microsoft Copilot creators
        </Typography> */}
        <br/>
        <br/>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Developed by ex-Microsoft Copilot AI creators
        </Typography>
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} More than 40x cheaper than ChatGPT Plus!
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Cancel Anytime
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} 40 times cheaper than Jasper!
        </Typography> */}
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Customised AI for Writing prompts
        </Typography>
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Worlds most powerful AI 
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Grounded on Bing Search results
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} High quality Citations/References  
        </Typography> */}
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Control AI with Refinements 
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Make it Shorter, Simpler, Detailed  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Add emojis, Increase Font size    
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} AI Recommended refinements  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Copy or Share generations
        </Typography>  
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} AI generated Images (coming soon)
        </Typography>  
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} AI generated Stories (coming soon)
        </Typography>   */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Custom AI for Writing and Refinements
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} AI Video tutorials 
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} (Coming Soon) File Upload  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} (Coming Soon) AI Powered Word Processor  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} (Coming Soon) End to End encryption  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} (Coming Soon) New Monthly AI tutorials
        </Typography>     */}
      </CardContent>
      
      {/* <CardActions>
      <Button size="small" variant='outline'>How do we provide all this for less than a dollar?</Button>
      </CardActions> */}
    </Card>
  );
}
