import React from 'react';
import { useNavigate } from "react-router-dom"
import { useAuthWrapper } from './AuthWrapper';
import Header from './Header';
import Footer from './Footer';

const Cancellation = () => {

    const navigate = useNavigate();
    useAuthWrapper();

    return (
        <div>
            <div className="container">
                <Header showLogin={false} />
                <div style={{ marginTop: "2%" }}>
                    <div className="">
                        <div className="privacy-policy" style={{ marginTop: "2%" }}>
                            <h1>Cancellation</h1>
                            <br />
                            <p><i>Last updated: September 9th, 2023</i></p>
                            <br /><br />
                            <h2>Our Services</h2>
                            <br />
                            <p>
                                ChatDragon.ai offers a text generation service. ChatDragon.ai can be accessed through{' '}
                                <a href="https://www.chatdragon.ai">https://www.chatdragon.ai</a> and its associated internet domains.
                            </p>
                            <br /><br />
                            <h2>Information ChatDragon.ai Collects</h2>
                            <br />
                            <p>
                                 We require your registered company address and domestic telephone number in India to be listed on the "Contact Us" or "Support" page on your website.
                            </p>

                            {/* Add more sections here... */}

                            <br /><br />
                            <h2>Contact Us</h2>
                            <br />
                            <p>If you have any inquiries regarding this Policy, kindly reach out to us at <a href="mailto:support@chatdragon.ai">support@chatdragon.ai</a>.</p>
                        </div>

                    </div>

                </div>

            </div>
            <Footer position='none'/>
        </div>


    );
};

export default Cancellation;
