import React from 'react';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useNavigate } from "react-router-dom"
import { useAuthWrapper } from './AuthWrapper';
import Header from './Header';
import Footer from './Footer';

const PrivacyPolicy = () => {

    const navigate = useNavigate();
    useAuthWrapper();

    return (
        <div>
            <div className="container">
                <Header showLogin={false} />
                <div style={{ marginTop: "2%" }}>
                    <div className="">
                        <div className="privacy-policy" style={{ marginTop: "7%" }}>
                            <h1 style={{fontWeight:400}}>Privacy Policy</h1>
                            <br/>
                            <p><i>Last updated: Jan 30th, 2024</i></p>
                            <br /><br />
                            <h3>Our Services</h3>
                            <br/>
                        
                            <p style={{fontSize:"12px"}}>
                            ChatDragon offers a news feed service. ChatDragon can be accessed through{' '}
                                <a href="https://www.chatdragon.ai">https://www.chatdragon.ai</a> and its associated internet domains.
                            </p>
                            <br />
                            <h3>Information we Collect</h3>
                            <br />
                            <p style={{fontSize:"12px"}}>
                                Upon visiting our website, specific information may be automatically collected from your device.
                                This information could encompass your IP address, device type, unique device identification numbers,
                                browser type, general geographic location (such as country or city-level location), and
                                additional technical data.
                                Furthermore, we may gather data about your device's interaction with our website. We employ this information for internal analytics and to enhance the
                                quality and relevance of our service.
                              
                            </p>
                            <br/>

                            {/* Add more sections here... */}

                        
                            <h2>Contact Us</h2>
                            <br />
                            <p style={{fontSize:"12px"}}>If you have any inquiries regarding this Policy, kindly reach out to us at <a href="mailto:support@chatdragon.ai">support@chatdragon.ai</a>.</p>
                        </div>

                    </div>

                </div>

            </div>
            <Footer  />
        </div>
    );
};

export default PrivacyPolicy;
