import { doc, onSnapshot, collection, getDoc } from "firebase/firestore"; //
import { db } from "../../config/firebase"; //connect to firestore db
import { actionTypes } from "../../statemanagement/reducer";

const paymentsCollection = collection(db, "payments");


 /**
   * firestore
   *   users
   *     nsjvnjdsnvjnsfngifsgfisn
   *        email: amansing5050@gmail.com
   *     sdsdsfdvwkvnfklnvkwfnklv
   *        email: karansingh50@gmail.com
   *   payments (paymentsCollectionRef)
   *     nsjvnjdsnvjnsfngifsgfisn (paymentInfoSnapshot)
   *        event.data.object
   */


//**FIX BUG STORE SESSION INFO IN BROWSER
export const listenForUpdatesInPaymentsCollection = (sub, dispatch) => {
  const paymentsCollectionRef = doc(paymentsCollection, sub);

  const unsubscribe = onSnapshot(paymentsCollectionRef, (paymentInfoSnapshot) => {
    console.log("Payments Collection has been modified");
    if (paymentInfoSnapshot.exists()) {
      console.log("Payment for the user exists in DB");
      const paymentData = paymentInfoSnapshot.data();
      console.log("Setting Payment data from DB to local state");
      dispatch({type: actionTypes.SET_PAYMENT_INFO, paymentInfo: paymentData}); //front-end is now aware that payment has been made
    //  dispatch({type: actionTypes.SET_LOGIN_LOADING, loginLoading: false});
    } else {
      console.log("User document doesn't exist");
      dispatch({type: actionTypes.SET_PAYMENT_INFO, paymentInfo: null});
    }
  });

  return unsubscribe; // Return a function to stop listening for changes when needed
};

// export const fetchActiveSubscriptions = async (userId) => {
//   const fetchSubscriptionData = async () => {
//     const subscriptionRef = doc(subscriptionsCollection, userId);
//     const subscriptionDoc = await getDoc(subscriptionRef);
//     if(subscriptionDoc.exists()){
//       return subscriptionDoc.data();
//     //  dispatch({type: actionTypes.SET_PAYPAL_SUBSCRIPTION, subscription: subscriptionData});
//     }
//   }

//   fetchSubscriptionData();
  
// }


