import React from 'react';
import { Button } from '@mui/material';
import axios from "axios";
import { useStripe } from '@stripe/react-stripe-js';
import { getStripeCheckoutUrl } from '../constants/helpers';
import HttpsIcon from '@mui/icons-material/Https';

// const instance2 = axios.create({
//    withCredentials: false, // Allow sending cookies with cross-origin requests
//  });

export const StripeCheckoutComponent = ({user}) => {
    const stripe = useStripe();

// const instance = axios.create({
//       withCredentials: false, // Allow sending cookies with cross-origin requests
//     });

  return (
    <Button className="get_started" style={{ margin: "auto"}} onClick={async (_)=> {
        //
        const response = await axios.post(getStripeCheckoutUrl(), {
            email: user.email,

         }, {withCredentials: false});
         
         console.log(`Response from initiating checkout session: ${JSON.stringify(response.data)}`);
         const {error} = await stripe.redirectToCheckout({sessionId: response.data.sessionId});

         if(error){
            console.log(error);
         }
    }}>
        Complete Payment with Stripe <HttpsIcon style={{ paddingLeft: "9px", fontSize: "x-large", paddingRight: "0px", paddingTop: "3px" }} />
    </Button>
  )
}
