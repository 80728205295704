import { doc, onSnapshot, collection, getDoc } from "firebase/firestore"; //
import { db } from "../../config/firebase"; //connect to firestore db
import { actionTypes } from "../../statemanagement/reducer";

const meterCollection = collection(db, "meter");


 /**
   * firestore
   *   meter is a meter collection
   *     nsjvnjdsnvjnsfngifsgfisn (sub) is a document reference (meterCollectionRef)
   *        {meter: 1}
   *     sdsdsfdvwkvnfklnvkwfnklv (sub)
   *        meter: 1
   *        
   */


//**FIX BUG STORE SESSION INFO IN BROWSER
export const listenForUpdatesInMeterCollection = (sub, dispatch) => {
  const meterCollectionRef = doc(meterCollection, sub);

  const unsubscribe = onSnapshot(meterCollectionRef, (meterCollectionSnapshot) => {

    if (meterCollectionSnapshot.exists()) {
      console.log("Payment for the user exists in DB");
      const meterData = meterCollectionSnapshot.data();
      console.log("Setting Payment data from DB to local state");
      dispatch({type: actionTypes.UPDATE_METER, meter: meterData.meter}); //front-end is now aware that payment has been made
    //  dispatch({type: actionTypes.SET_LOGIN_LOADING, loginLoading: false});
    } else {
      console.log("User does not have any creations as yet");
      dispatch({type: actionTypes.UPDATE_METER, meter: 0});
    }
  });

  return unsubscribe; // Return a function to stop listening for changes when needed
};

// export const fetchActiveSubscriptions = async (userId) => {
//   const fetchSubscriptionData = async () => {
//     const subscriptionRef = doc(subscriptionsCollection, userId);
//     const subscriptionDoc = await getDoc(subscriptionRef);
//     if(subscriptionDoc.exists()){
//       return subscriptionDoc.data();
//     //  dispatch({type: actionTypes.SET_PAYPAL_SUBSCRIPTION, subscription: subscriptionData});
//     }
//   }

//   fetchSubscriptionData();
  
// }


