import { useStateValue } from "../statemanagement/StateProvider";
import { actionTypes } from "../statemanagement/reducer";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";
import axios from "axios";
import AtomicSpinner from 'atomic-spinner';
import { deleteSubscription } from "../components/firebaseHelpers/subscriptionsCollection";
import { useAuthWrapper } from "./AuthWrapper";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import BasicCard from "../othercomponents/BasicCard";
import { getCancelPaypalSubscriptionUrl, getRedirectUri } from "../components/constants/helpers";
import PaidCard from "../othercomponents/PaidCard";

export const Login = () => {
    //axios.defaults.withCredentials = true;

    const [{ user, subscription, paymentInfo, meter }, dispatch] = useStateValue();
    useAuthWrapper();
    const location = useNavigate();


//    const [loading, setLoading] = useState(false);
    const navigateHome = () => location("/");
    const login = useGoogleLogin({
        flow: 'auth-code',
        scope: "profile",
        ux_mode: "redirect",
        // redirect_uri: "https://basic-chat-75e5e--paypal-auth-verify-y2lvilsr.web.app/login",
        redirect_uri: getRedirectUri(), //url to which google has to redirect after authentication is complete 
    });

    const sign_out_button_click = async (_) => {
         //google logout
         
         googleLogout();

         //local state logout
         dispatch({ type: actionTypes.SET_USER, user: null }); //remove from app state
         //dispatch({ type: actionTypes.SET_STRIPE_SUBSCRIPTION, subscription: null });

         console.log("Attempting to destroy user session data in backend");
         localStorage.removeItem("user"); //remove from browser storage
        //  axios.get(getEndAuthSessionApi(), {
        //      withCredentials: true
        //  }).then((response) => {
        //      console.log(response.data)
        //  }).catch((error) => {
        //      console.error(error);
        //  })
    }

    const cancel_button_click = async (_) => {
        try {
            const response = await axios.post(getCancelPaypalSubscriptionUrl(), {
                subscriptionID: subscription.data.id
            }, {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            });
            if (response.data.isCancelled == true) {
                if (deleteSubscription(user)) {
                    dispatch({ type: actionTypes.SET_STRIPE_SUBSCRIPTION, subscription: null });
                }
            }
        } catch (error) {
            console.log("Failed to cancel subscription");
        }
    }

    return (
        <div>
            <div className="container">
                <Header showLogin={false} />
                <div className="container_body" style={{paddingTop:"10%"}}>
 
                    {!!user &&
                        <div>
                            {!paymentInfo && <div> <BasicCard meter={meter} navigateHome={navigateHome} login={login} user={user} subscription={subscription} paymentInfo={paymentInfo}/> <br/>
                            
                            {/* <AddOnCard/> */}
                            
                            </div>}
                            
                            {/* {!!subscription && <div style={{paddingTop:"5%", paddingBottom:"5%"}}><h3>Subscription: {subscription.credits} credits left</h3></div>} */}
                            {!!paymentInfo && <PaidCard meter={meter} paymentInfo={paymentInfo} user={user} location={location} />}
                            <div style={{paddingTop:"2%", paddingBottom:"5%"}}><Button className="cancel_button" onClick={sign_out_button_click}>Sign Out</Button></div>
                            {/* {!!subscription && <div style={{paddingBottom:"5%"}}><Button className="cancel_button" onClick={cancel_button_click} >Cancel Subscription</Button></div>} */}
                        </div>

                    }
                    {!user &&
                        <div className="container_copy" style={{ textAlign: "center", paddingTop: "1%" }}>
                            
                            <BasicCard meter={meter} login={login} user={user} subscription={subscription} paymentInfo={paymentInfo}/>
                            <br/>
                            {/* <AddOnCard/> */}
                            <br/>
                            {/* <Button variant="contained" onClick={(_) => login()}>
                                Sign in with Google <HttpsIcon style={{ paddingLeft: "9px", fontSize: "x-large", paddingBottom: "5px", paddingRight: "0px", paddingTop: "3px" }} />
                            </Button> */}

                        </div>}
                    {/* {<div style={{ display: "flex", justifyContent: "center" }}><AtomicSpinner /></div>} */}

                </div>
            </div>
            <Footer />
        </div>
    );

}

