import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { Button, CardActionArea, CardActions } from '@mui/material';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { forwardRef } from 'react';

const CardComponent = forwardRef(({name, body, image, source}, ref) => {
  return (
    <>
    <Card ref={ref} sx={{ boxShadow:"2px 2px 2px 2px rgba(0,0,0,0), 2px 2px 2px 2px rgba(0,3,1,0.14), 2px 2px 2px 2px rgba(0,0,0,0.12)", fontFamily:"'Playfair Display', serif" }}>
      <CardActionArea>
       {image != "" && <CardMedia
          component="img"
          image={image}
          alt="green iguana"
        /> }
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily:"'Playfair Display', serif"  }}>
            {name}
          </Typography>
          {/* <Typography gutterBottom variant="body" component="div" sx={{ fontFamily:"'Playfair Display', serif"  }}>
            {body}
          </Typography> */}
        </CardContent>
      </CardActionArea>
      <CardActions style={{ justifyContent: "space-between" }}>

            <Button size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }} onClick={(_)=> console.log("")}>
            <StickyNote2Icon style={{fontSize:"20px", opacity:1, marginTop:"1px", padding:"5px"}}/> {source}
            </Button>

            {/* <Button size="small" color="primary" style={{ color: "black", fontFamily: "'bitter', serif", textTransform:"capitalize" }} onClick={(_)=> console.log("")}>
            <SearchIcon style={{fontSize:"20px", opacity:1, marginTop:"1px", padding:"3px"}}/> Discuss with AI 
            </Button> */}
      </CardActions>

    </Card>
    <br/>
    </>
  );
})

export default CardComponent;