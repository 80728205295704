// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithRedirect, signInWithPopup, GoogleAuthProvider, signOut, getRedirectResult } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
// import { getStripePayments } from "@stripe/firestore-stripe-payments";



// import { getAnalytics } from "firebase/analytics";

// import {getAuth, GoogleAuthProvider} from 'firebase/auth'

//refers to online instance, Auth actions and CRUD actions aginast firebase project
// const firebaseConfig = {
//   apiKey: "AIzaSyDCRu-nhV0JlRaOrpievMlxo1bDcTEa9MY",
//   authDomain: "chatdragon.ai",
//   projectId: "basic-chat-75e5e",
//   storageBucket: "basic-chat-75e5e.appspot.com",
//   messagingSenderId: "682480431825",
//   appId: "1:682480431825:web:71bb0323751fa8823f4bf0",
//   measurementId: "G-MYYTB9XMYD"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyCxrRQ6i5Cq0O1qy75-kKEFfm-VFahWd1A",
//   authDomain: "write-chat-f9165.firebaseapp.com",
//   projectId: "write-chat-f9165",
//   storageBucket: "write-chat-f9165.appspot.com",
//   messagingSenderId: "160885138360",
//   appId: "1:160885138360:web:4f0e157e97d8e3b8dae54d",
//   measurementId: "G-RYNDH5YL0H"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyA0syV4Olhabv3StHGMqIWlKPN0R5A3I8s",
//   authDomain: "basic-chat-streaming-1963.firebaseapp.com",
//   projectId: "basic-chat-streaming-1963",
//   storageBucket: "basic-chat-streaming-1963.appspot.com",
//   messagingSenderId: "269737506287",
//   appId: "1:269737506287:web:21b36c3165add79122721a"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAxWbnV_uB8k1U6phS0KgndycUpZuDQPvA",
  authDomain: "chatdragon-b025a.firebaseapp.com",
  projectId: "chatdragon-b025a",
  storageBucket: "chatdragon-b025a.appspot.com",
  messagingSenderId: "922833575832",
  appId: "1:922833575832:web:76f82c2149a2babaa515ed",
  measurementId: "G-5RN88VNJFB"
};

export const app = initializeApp(firebaseConfig);
// export const db = getFirestore(app);
export const provider = new GoogleAuthProvider(); 
// export const payments = getStripePayments(app, {
//   productsCollection: "products",
//   customersCollection: "customers",
//   });


// provider.setCustomParameters({
//   prompt: "select_account"  
// })


export const auth = getAuth(app); //global auth object linked to app 


export const getRedirectFunc = () => getRedirectResult(auth);
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const signInWithGoogleRedirect = () => signInWithRedirect(auth, new GoogleAuthProvider());
export const signOutFromGoogle = () => signOut(auth);

export const db = getFirestore(app); //exporting db reference

// export const getUserFromDB = async ()

export const createUserDocumentFromUserAuth = async (userCred) => {
  const userDocRef = doc(db, "users", userCred.uid);
  const userSnapshot = await getDoc(userDocRef);

  if(!userSnapshot.exists()){
    const {displayName, email} = userCred;
    const createdAt = new Date();
    const tier = "PAID";
    const attempts = -1;

    try{
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        tier,
        attempts
      })
    }
    catch (e){
      console.log("error in creating the user");
    }
  }

  return userDocRef;
  
}


// const analytics = getAnalytics(app);
// export const auth =  getAuth(app);
// export const googleProvider = new GoogleAuthProvider();