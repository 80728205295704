import { useEffect } from "react";
import axios from "axios";
import { actionTypes } from '../statemanagement/reducer';
import { useStateValue } from "../statemanagement/StateProvider"
import { getModel } from "../utils/models";
import { useNavigate } from "react-router-dom";
import { signedInUser, unsignedUser } from "../statemanagement/authHelpers/authHelper";
import { updateMeter } from "../components/firebaseHelpers/updateMeterCollection";
import { getTextGenerationUrl, getTextRefinementUrl, getBasicChatLanguageModel } from "../components/constants/helpers";

let cancelSource;

const useAIService = (term, followup, refToScrollableDiv, user, subscription) => {

  //const basicUrl = "https://us-central1-basic-chat-75e5e.cloudfunctions.net/app";
 // const basicUrl = "http://localhost:3001";//https://us-central1-basic-chat-75e5e.cloudfunctions.net/app
  //const multiTurnUrl = "https://us-central1-basic-chat-75e5e.cloudfunctions.net/app/followup"
  
  //const basicUrl = "https://authservice1964.azurewebsites.net/";
  //const multi = "https://authservice1964.azurewebsites.net/multi";
  //const question = "https://authservice1964.azurewebsites.net/question";



//const basicUrl = "https://basic1964.azurewebsites.net/";
//const basicUrlGpt = "https://basic1964.azurewebsites.net/";

//const basicUrl = "http://localhost:8080/"
//const multi = "http://localhost:8080/multi"
//const multi = "https://basic1964.azurewebsites.net/multi"
//const question = "https://basic1964.azurewebsites.net/question"
//const question = "http://localhost:8080/question"

//const basicUrl = "http://localhost:8080";  
//https://us-central1-basic-chat-75e5e.cloudfunctions.net
  //https://zippy-vigil-394222.ew.r.appspot.com/
  //http://localhost:5000

  
  const navigate = useNavigate();

  const [{ conversationHistory, streaming, editing, unsignedCount, paymentInfo, meter }, dispatch] = useStateValue();

  useEffect(()=>{

    const fetch = async () => {
    
      dispatch({type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: true});
      const response = await axios.post(getTextRefinementUrl(), { messages: conversationHistory });
     
      if(!!response["data"] && response["data"] != "" ){
        
        dispatch({
          type: actionTypes.SET_NEXT_Q,
          next:  response["data"].includes(":") ? response["data"].split(":")[1].trim(): response["data"]
        });

      }
      dispatch({type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: false});
      
    }

   /**
    * {
    *  "data": "make it shorter"
    * }
    */
    const fetchRefinementRecommendation = async () => {
      //getTextRefinementUrl
      dispatch({type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: true});//mini-loader start
      const response = await axios.post(getTextRefinementUrl(), { messages: conversationHistory }); //fetch next refinement recommendation
      if(!!response["data"] && response["data"] != ""){
        dispatch({
          type: actionTypes.SET_NEXT_Q,
          next: response["data"]
        })
      }
      dispatch({type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: false});//mini-loader stop
    }
    

    if(!editing && !streaming && conversationHistory.length>0 && !!conversationHistory[conversationHistory.length-1]["role"] && conversationHistory[conversationHistory.length-1]["role"] == "assistant"){
     // fetchRefinementRecommendation();
      // fetch();
     dispatch({type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: false});
     dispatch({type: actionTypes.SET_NEXT_Q, next: getRandomRefineExample()});

    }

  }, [conversationHistory, streaming]);

  const refineExamples = [
    "Make it formal",
    "Make it professional",
    "Make it Shorter",
    "Make it more Elaborate",
    "Make it longer",
    "Add more information",
];

const getRandomRefineExample = () => {
    const randomIndex = Math.floor(Math.random() * refineExamples.length);
    return refineExamples[randomIndex];
}
/** 
 * nonuser
 * paid
 * unpaid
*/
const paid = (user, paymentInfo) => !!user && !!paymentInfo && paymentInfo.payment_status == "paid";
const unpaid = (user, paymentInfo) => !!user && (!paymentInfo || paymentInfo.payment_status != "paid");

  const gptService = async (mode) => {

    let accumulatedResponse = ""; 

    //getTextGenerationUrl()//https://infolitsearchservice.azurewebsites.net/
    const response = await fetch(getBasicChatLanguageModel(),{ 
      method: "POST",
      headers: {
        Accept: "text/html",
        "Content-Type":"application/json"
      },
      body: JSON.stringify({ "messages": conversationHistory })
    });

    if(!response.ok || !response.body){
      throw response.statusText;
    }

    //handle the stream
    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    let loopCount = 0;
    const spaUpdateInterval = 1; 
    
    dispatch({
      type:  actionTypes.SET_STREAMING,
      streaming: true
    }); 

    dispatch({
      type: actionTypes.SET_LOADING,
      loading: false
    }); 

    while (true) {
      const {value, done } = await reader.read();
      if(done) { //if they have exceeded limit they will be downgraded
        dispatch({
          type:  actionTypes.SET_STREAMING,
          streaming: false
        }); 
        /* --main;
        if(!!user && mode=="SINGLE_SHOT"){ 
          await updateMeter(user, meter+1, dispatch, paymentInfo);
        }
        */
        //return;
        break; 
      }
      const decodedChunk = decoder.decode(value, {stream: true});
      accumulatedResponse+=decodedChunk; 
      if(loopCount % spaUpdateInterval === 0){ //at regular interval
        dispatch({
          type: actionTypes.UPDATE_CONVERSATION_HISTORY,
          enrichedConversationHistory:[...conversationHistory, {role:"assistant", content:accumulatedResponse}]
        }) 
      }
      loopCount++;
    }

    
    dispatch({
      type: actionTypes.UPDATE_CONVERSATION_HISTORY,
      enrichedConversationHistory:[...conversationHistory, {role:"assistant", content:accumulatedResponse}]
    }) ;
  
  }


  useEffect(() => {
    
    if (term.length > 0) {
      //fetchData(basicUrl);
      gptService("SINGLE_SHOT");
    }
  }, [term]);


  useEffect(() => {
    if (followup.length > 0) {
      //fetchData(multi);
      gptService();
    }
  }, [followup]);
};



export default useAIService;
// import { useEffect } from "react";
// import axios from "axios";
// import { actionTypes } from '../statemanagement/reducer';
// import { useStateValue } from "../statemanagement/StateProvider"
// import { getModel } from "../utils/models";
// import { useNavigate } from "react-router-dom";
// import { signedInUser, unsignedUser } from "../statemanagement/authHelpers/authHelper";
// import { getTextGenerationBaseApi, getTextGenerationFollowupApi, getTextGenerationMultiturnApi } from "../components/constants/helpers";

// let cancelSource;

// const useAIService = (term, followup, refToScrollableDiv, user, subscription) => {

//   // const basicUrl = getTextGenerationBaseApi();
//   // const multi = getTextGenerationMultiturnApi();
//   // const question = getTextGenerationFollowupApi();

//   const basicUrl = "https://basic1964.azurewebsites.net";
//   const multi = `${basicUrl}/multi`;
//   const question = `${basicUrl}/question`;

  
//   const navigate = useNavigate();

//   const [{ conversationHistory, streaming, editing, unsignedCount }, dispatch] = useStateValue();

//   const fetchData = async (url) => {
    
//     if(cancelSource){
//       cancelSource.cancel("cancelled");
//     }

//     cancelSource = axios.CancelToken.source();
//     window.gtag('event', 'Initiated_Request');
//     try {      
//       // mkt_ext:"&mkt=en-in", model: !!user?"GPT_4":"GPT_3.5"
//       // const model = !!user ? "gpt-4" : "gpt-3.5-turbo-16k";


//       await axios.post(url, { messages: conversationHistory, mkt_ext: "&mkt=en-us", model: getModel(subscription) }, {
//         responseType: 'stream',
//         onDownloadProgress: (progressEvent) => {
//           progressEvent.currentTarget.responseText.split("<BREAK>").filter(u => u.trim() !== '').map(u => {
//             if (!!u) {
              
//               if (u == "[DONE]") {
//                 dispatch({
//                   type: actionTypes.SET_STREAMING,
//                   streaming: false
//                 });
                
//                  return;
//               }

//                 dispatch({
//                   type: actionTypes.UPDATE_CONVERSATION_HISTORY,
//                   enrichedConversationHistory: [...conversationHistory, { "role": "assistant", "content": u }] // or derived data from it
//                 });
                
//                 dispatch({
//                   type: actionTypes.SET_LOADING,
//                   loading: false
                  
//                 });

//                 dispatch({
//                   type: actionTypes.SET_STREAMING,
//                   streaming: true
//                 });
              
//             }
            
//           })
//         },
//         cancelToken: cancelSource.token
//       }
//       );

      
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }    
//   };


//   const updateUserStats = (url) => {

//     if(signedInUser) return; //no restrictions on signed in users
//     if(url != basicUrl) return; //no restrictions on multi-turn or questions 
//     console.log("One Shot prompt");

//     const getUnsignedUserCount = localStorage.getItem("unsignedCount");
//     console.log(`Unsigned user count is: ${getUnsignedUserCount}`);
//     if(!!getUnsignedUserCount && Number(getUnsignedUserCount)>1){
//           localStorage.setItem("unsignedCount", null);
//           navigate("/login");
//     }

//     localStorage.setItem("unsignedCount", "1");
//     console.log(`Local Storage updated to: ${localStorage.getItem("unsignedCount")}`);

   
//   }

//   useEffect(()=>{

//     const fetch = async () => {
    
//       dispatch({type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: true});
//       const response = await axios.post(question, { messages: conversationHistory });
     
//       if(!!response["data"] && response["data"] != "" ){
        
//         dispatch({
//           type: actionTypes.SET_NEXT_Q,
//           next:  response["data"].includes(":") ? response["data"].split(":")[1].trim(): response["data"]
//         });

//       }
//       dispatch({type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: false});
      
//     }
    

//     if(!editing && !streaming && conversationHistory.length>0 && !!conversationHistory[conversationHistory.length-1]["role"] && conversationHistory[conversationHistory.length-1]["role"] == "assistant"){
//       fetch();
//     }

//   }, [conversationHistory, streaming]);

//   useEffect(() => {
    
//     if (term.length > 0) {
//       fetchData(basicUrl);
//     }
//   }, [term]);


//   useEffect(() => {
//     if (followup.length > 0) {
//       fetchData(multi);
//     }
//   }, [followup]);
// };



// export default useAIService;
