import "./Search.css";
import { Button } from '@mui/material';
import { actionTypes } from "../statemanagement/reducer";
import { clientCache } from "../mock/ClientCache";
import { useNavigate } from "react-router-dom";
import CardComponent from "./CardComponent";
import { useEffect, useState, useRef, useCallback } from "react";
import useHeadlines from "../customhooks/useHeadlines";
import StoreIcon from '@mui/icons-material/Store';
import useFeed from "../customhooks/useFeed";


const LandingPrompts = ({ dispatch, streaming, postFunc, user, headlines }) => {

    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    //HOLD: useFeed(page);
    //useFeed(page);
    const {isLoading, hasNextPage} = useFeed(page);
    console.log(`Loading: ${isLoading}`);
    console.log(`Has next page: ${hasNextPage}`);
    useEffect(()=>{
        console.log("Landing Prompt mounted");
    },[])
    //useHeadlines();
    //console.log(`Headlines from state: ${JSON.stringify(headlines)}`)

    const handleClick = (term, cache = false, edit = false) => {
        if (streaming) return;

        postFunc(edit);

        if (cache) {

            setTimeout(() => {
                dispatch({
                    type: actionTypes.UPDATE_CONVERSATION_HISTORY,
                    enrichedConversationHistory: clientCache(term)
                });
            }, 50);

        } else {
            dispatch({ type: 'SET_SEARCH_TERM', term });
        }

        window.gtag('event', 'Landing_Prompt_Assistance', {
            event_category: 'Landing_Prompt_Assistance',
            event_label: term,
        });

    };

    const intersectionObserver = useRef(null);
    const lastChildRef = useCallback(lastElement => {
        console.log("inside useCallback")
        if(isLoading) return;

        //if intersection observer has already been set then remove it
        if(intersectionObserver.current) intersectionObserver.current.disconnect();

        //observe the last element

        //attach intersectionObserver to new last element
        intersectionObserver.current = new IntersectionObserver(children => { //create a new observer
            if(children[0].isIntersecting && hasNextPage){
                console.log("Scrolling near end/last child");
                setPage(p=>p+1);
            }
        })

        if(lastElement){
            intersectionObserver.current.observe(lastElement); //start observer to last element
        }

    },[isLoading, hasNextPage])

    const constructFeed = (headlines) => {
        return headlines.map((headline,i)=>{
            
            if((i+1) == headlines.length){
                console.log("setting ref to last element");
                return <CardComponent ref={lastChildRef} source={headline.provider[0].name} name={headline.name} body={headline.description} image={headline.image.contentUrl}/>;
            }
            return <CardComponent source={headline.provider[0].name} name={headline.name} body={headline.description} image={headline.image.contentUrl}/>;
        })
    }

    return (

        <div>
        <div className='prompt_assistance_button_group_countainer'>
           
            {!user && <div style={{ textAlign: "center" }}>
            </div>}
        </div>
        <br/>
        {constructFeed(headlines)}
        {/* <CardComponent name={"test123"} body={"Battersea Power Station’s redevelopment project aims to transform the iconic landmark into a vibrant mixed-use neighborhood, preserving its historic structure while adding housing, offices, shops, and cultural venues. Improved connectivity and new public spaces will revitalise this symbol of London’s industrial heritage. "} image={"https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/ANI-20231221360-0_1703184602149_1703184634419.jpg"}/>
        <CardComponent name={"test"} body={"Battersea Power Station’s redevelopment project aims to transform the iconic landmark into a vibrant mixed-use neighborhood, preserving its historic structure while adding housing, offices, shops, and cultural venues. Improved connectivity and new public spaces will revitalise this symbol of London’s industrial heritage. "} image={"https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/ANI-20231221360-0_1703184602149_1703184634419.jpg"}/>
        <CardComponent name={"test"} body={"Battersea Power Station’s redevelopment project aims to transform the iconic landmark into a vibrant mixed-use neighborhood, preserving its historic structure while adding housing, offices, shops, and cultural venues. Improved connectivity and new public spaces will revitalise this symbol of London’s industrial heritage. "} image={"https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/ANI-20231221360-0_1703184602149_1703184634419.jpg"}/>
        <CardComponent name={"test"} body={"Battersea Power Station’s redevelopment project aims to transform the iconic landmark into a vibrant mixed-use neighborhood, preserving its historic structure while adding housing, offices, shops, and cultural venues. Improved connectivity and new public spaces will revitalise this symbol of London’s industrial heritage. "} image={"https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/ANI-20231221360-0_1703184602149_1703184634419.jpg"}/>
        <CardComponent name={"test"} body={"Battersea Power Station’s redevelopment project aims to transform the iconic landmark into a vibrant mixed-use neighborhood, preserving its historic structure while adding housing, offices, shops, and cultural venues. Improved connectivity and new public spaces will revitalise this symbol of London’s industrial heritage. "} image={"https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/ANI-20231221360-0_1703184602149_1703184634419.jpg"}/>
        <CardComponent name={"test"} body={"Battersea Power Station’s redevelopment project aims to transform the iconic landmark into a vibrant mixed-use neighborhood, preserving its historic structure while adding housing, offices, shops, and cultural venues. Improved connectivity and new public spaces will revitalise this symbol of London’s industrial heritage. "} image={"https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/ANI-20231221360-0_1703184602149_1703184634419.jpg"}/>
        <CardComponent name={"test"} body={"Battersea Power Station’s redevelopment project aims to transform the iconic landmark into a vibrant mixed-use neighborhood, preserving its historic structure while adding housing, offices, shops, and cultural venues. Improved connectivity and new public spaces will revitalise this symbol of London’s industrial heritage. "} image={"https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/ANI-20231221360-0_1703184602149_1703184634419.jpg"}/>
        <CardComponent name={"test"} body={"Battersea Power Station’s redevelopment project aims to transform the iconic landmark into a vibrant mixed-use neighborhood, preserving its historic structure while adding housing, offices, shops, and cultural venues. Improved connectivity and new public spaces will revitalise this symbol of London’s industrial heritage. "} image={"https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/ANI-20231221360-0_1703184602149_1703184634419.jpg"}/>
        <CardComponent name={"test"} body={"Battersea Power Station’s redevelopment project aims to transform the iconic landmark into a vibrant mixed-use neighborhood, preserving its historic structure while adding housing, offices, shops, and cultural venues. Improved connectivity and new public spaces will revitalise this symbol of London’s industrial heritage. "} image={"https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/ANI-20231221360-0_1703184602149_1703184634419.jpg"}/>
        <CardComponent name={"test"} body={"Battersea Power Station’s redevelopment project aims to transform the iconic landmark into a vibrant mixed-use neighborhood, preserving its historic structure while adding housing, offices, shops, and cultural venues. Improved connectivity and new public spaces will revitalise this symbol of London’s industrial heritage. "} image={"https://www.hindustantimes.com/ht-img/img/2023/12/21/1600x900/ANI-20231221360-0_1703184602149_1703184634419.jpg"}/> */}
        <br/>
        <br/>
        </div>
    );
};

export default LandingPrompts;

