export const fixedExamples = [ {
    term: "Tips for Salary negotiation",
    label: "Salary negotiation tips"
},
{
    term: "Write a professional recommendation for a colleague",
    label: "Write a recommendation"
},
{
    term: "Provide all steps to plan a wedding in India",
    label: "Wedding planning tips (India)"
},
{
    term: "Steps to prepare for an MBA in India? Include top colleges and entrance exams",
    label: "Steps to Prepare for MBA"
}];

export const promptExamples = [
    {
        term: "Draft an email to reschedule meeting",
        label: "Write email to reschedule meeting"
    },
    {
        term: "Asia Cup 2023 schedule",
        label: "Asia Cup 2023 schedule"
    },
    {
        term: "What did Chandrayaan rover find?",
        label: "What did Chandrayaan rover find?"
    },
    {
        term: "Asia Cup 2023, Pakistan vs Nepal Live Score",
        label: "Asia Cup 2023, Pakistan vs Nepal Live Score"
    },
    {
        term: "Write a thank you WhatsApp message",
        label: "Write a Thank you WhatsApp message"
    },
    {
        term: "Asia Cup 2023, When is India vs Pakistan match?",
        label: "Asia Cup 2023, When is India vs Pakistan match?"
    },
    {
        term: "Create 30 min workout session for me today",
        label: "30 min Workout session"
    },
    {
        term: "Asia Cup 2023, Is Kohli playing?",
        label: "Asia Cup 2023, Is Kohli playing?"
    },
    {
        term: "Steps I need to take to start preparing for CAT exam in India with timeline",
        label: "CAT prep timeline"
    },
    // {
    //     term: "Discuss the influence of Bollywood on Indian culture",
    //     label: "Bollywood's influence"
    // },
    {
        term: "Tips on dealing with a bad manager",
        label: "Dealing with bad manager"
    },
    {
        term: "plan a 30 min yoga session for beginners",
        label: "30 min yoga session for beginners"
    },
    {
        term: "How do I address relationship issue with partner",
        label: "How to address relationship issue with partner"
    },
    {
        term: "Asia Cup 2023, Is Kohli fit?",
        label: "Asia Cup 2023, Is Kohli fit?"
    },
    // {
    //     term: "What is (x+y)^3, when x=32 and y=23",
    //     label: "Solve: (x+y)^3, x=32, y=23"
    // },
    // {
    //     term: "Discuss the traditional dance forms of Kerala",
    //     label: "Kerala dance forms"
    // },
    // {
    //     term: "Guide me through the steps to solve complex calculus problems",
    //     label: "Calculus problem-solving guide"
    // },
    {
        term: "Provide a guide on how to invest in Mutual funds in India",
        label: "Mutual Funds"
    },
    {
        term: "Introduce the basics of Machine Learning and its applications",
        label: "Machine Learning"
    },
    {
        term: "Software Engineering interview tips",
        label: "Software Engineering interview prep"
    },
    {
        term: "Help me with dealing with depression",
        label: "Dealing with depression"
    },
    {
        term: "Explain the concepts of organic chemistry with examples",
        label: "Organic Chemistry concepts"
    },
    {
        term: "Write an essay on stock market, include sub-titles",
        label: "Essay: Stock Market"
    },
    {
        term: "How to become an Instagram influencer? add emojis",
        label: "Become Instagram influencer"
    },
    {
        term: "What are the top tourist destinations in North India?",
        label: "North Indian tourism"
    },
    {
        term: "Write a review on the latest Amazon Prime series in India",
        label: "Amazon Prime series review"
    },
    {
        term: "Discuss the journey and achievements of ISRO",
        label: "ISRO's journey"
    },
    {
        term: "Compare iPhone 14 pro and Samsung S22, give output in table form, give synthesis of user feedback, assume user is in India",
        label: "iPhone 14 pro vs Samsung S22"
    },
    {
        term: "Compare Royal Enfield Classic 350 and Jawa 42 for an Indian rider",
        label: "Royal Enfield vs Jawa 42"
    },
    {
        term: "Write a message wishing someone for Diwali, include emojis",
        label: "Diwali greeting"
    },
    {
        term: "Recommend top 5 Indian authors and their must-read books",
        label: "Top Indian authors"
    },
    {
        term: "Provide a brief about the Indian Premier League (IPL) cricket",
        label: "About IPL"
    },
    {
        term: "Describe the festivals of South India",
        label: "South Indian festivals"
    },
    // {
    //     term: "Discuss the role of Mahatma Gandhi in Indian freedom struggle",
    //     label: "Role of Mahatma Gandhi"
    // },
    {
        term: "List effective time-management techniques for college students",
        label: "Time-management tips"
    },
    {
        term: "Tips to improve mental health",
        label: "Mental health"
    },
    {
        term: "Write a tweet about Virat Kohli",
        label: "Tweet on Kohli"
    },
    {
        term: "Steps to start an online boutique selling Indian handcrafted jewelry? Provide marketing tips",
        label: "Start Indian jewelry boutique"
    },
    {
        term: "Give me a tutorial on Python Programming",
        label: "Tutorial on Python"
    },
    {
        term: "Write a short Happy birthday greeting for a friend use [name], add emoji",
        label: "Write Birthday greeting"
    },
    // {
    //     term: "How to save money while shopping online in India?",
    //     label: "Save money on online shopping"
    // },
    {
        term: "Describe the steps to create a Paytm account",
        label: "Create a Paytm account"
    },
    {
        term: "Compose a vibrant greeting for Navaratri/Durga Puja to send to [name], add emoji",
        label: "Navaratri greeting"
    },
    {
        term: "How to prepare for startup pitches in India? Add infographics",
        label: "Startup pitch preparation"
    },
    {
        term: "How to prepare for the UPSC Civil Services Examination?",
        label: "UPSC exam preparation guide"
    },
    {
        term: "Describe the festivities and rituals of Durga Puja",
        label: "About Durga Puja"
    },
    {
        term: "How has the IT industry shaped the city of Bangalore?",
        label: "IT industry in Bangalore"
    },
    {
        term: "Provide tips for sustainable living in Indian cities",
        label: "Sustainable living"
    },
    {
        term: "Compose a warm Raksha Bandhan greeting for a sibling using [name], add emoji",
        label: "Raksha Bandhan greeting"
    },
    {
        term: "Draft a heartfelt message to celebrate Janmashtami with friends, include [name] and emoji",
        label: "Janmashtami greeting"
    },
    {
        term: "Write an article on the significance of the Taj Mahal and its global appeal",
        label: "Taj Mahal's significance"
    },
    {
        term: "Explain different types of Indian fabrics and their uses in fashion",
        label: "Indian fabrics in fashion"
    },
    {
        term: "How can one start an online business in India?",
        label: "Online business in India"
    },
    {
        term: "Suggest five travel destinations in India for adventure enthusiasts",
        label: "Adventure travel destinations"
    },
    {
        term: "Recommend Indian spices that should be a staple in every kitchen worldwide",
        label: "Must-have Indian spices"
    },
    {
        term: "How can one achieve fluency in spoken English? Add tips for Indians",
        label: "Achieve English fluency"
    },
    {
        term: "Write a note on the importance of the Ganges river in India",
        label: "Importance of Ganges"
    },
    {
        term: "How is Diwali celebrated across various parts of India? Include rituals and traditions",
        label: "Diwali celebrations"
    },
    {
        term: "Guide to finding a job in the IT sector in India",
        label: "Finding IT jobs"
    },
    {
        term: "Recommendations for top Indian music albums of 2022",
        label: "Indian music albums"
    },
    {
        term: "Steps to prepare aloo paratha? Include tips for making it soft",
        label: "Make soft aloo paratha"
    },
    {
        term: "Discuss India's performance in the 2022 Olympics",
        label: "India in 2022 Olympics"
    },
    {
        term: "What is the influence of Western culture on Indian youth?",
        label: "Western culture influence"
    },
    {
        term: "Recommend the best online courses for learning data science in India",
        label: "Data science courses"
    },
    {
        term: "Steps to cook a delicious Chicken Biryani, add suitable images",
        label: "Cook Chicken Biryani"
    },
    {
        term: "Describe the architectural wonders of Mughal India",
        label: "Mughal architecture"
    },
    {
        term: "Provide steps to make a TikTok video, add emojis",
        label: "Make a TikTok video"
    },
    {
        term: "Explain the features of the Indian Monsoon and its impact on agriculture",
        label: "Indian Monsoon & agriculture"
    },
    // {
    //     term: "What are the effects of the 2021 economic reforms in India?",
    //     label: "2021 Indian economic reforms"
    // },
    {
        term: "Guide to maintaining a healthy lifestyle in India, include diet, exercise, and wellness tips",
        label: "Healthy lifestyle tips"
    },
    {
        term: "How to prepare for the JEE Main?",
        label: "JEE Main preparation"
    },
    {
        term: "List some must-watch regional films from India of 2021",
        label: "Must-watch regional films"
    },
    {
        term: "Draft a motivational quote for students preparing for exams, include emojis",
        label: "Motivational quotes"
    },
    {
        term: "Discuss the different styles of Indian classical dance",
        label: "Indian classical dance"
    },
    {
        term: "What are the benefits of using cow dung as fuel in rural India?",
        label: "Cow dung as fuel"
    },
    {
        term: "How to make masala chai at home? Include variations",
        label: "Make masala chai"
    },
    {
        term: "List popular Indian dishes from different states",
        label: "Popular Indian dishes"
    },
    {
        term: "How to find internships in India for students?",
        label: "Tips on finding Internships"
    },
    {
        term: "Discuss the rise and impact of K-Pop in India",
        label: "K-Pop's impact in India"
    },
    {
        term: "Steps to learn a musical instrument at home, add references to Indian music",
        label: "Learn a musical instrument"
    },
    {
        term: "Provide a concise history of the Indian Railways",
        label: "History of Indian Railways"
    },
    {
        term: "Tips to succeed in the Indian stock market, include recent trends",
        label: "Succeed in stock market"
    },
    {
        term: "Recommend books on ancient Indian history and its dynasties",
        label: "Ancient Indian history books"
    },
    {
        term: "Discuss the cultural significance of the saree in Indian society",
        label: "Significance of the saree"
    },
    {
        term: "List famous historical landmarks of Delhi and their importance",
        label: "Historical landmarks, Delhi"
    },
    {
        term: "How to make homemade samosas? Include variations",
        label: "Make homemade samosas"
    },
    {
        term: "What are the current fashion trends in India for summer",
        label: "Indian fashion trends"
    },
    {
        term: "How to publish a book in India? Add steps for both traditional and self-publishing",
        label: "Publish a book"
    },
    {
        term: "Recommend must-visit temples in South India",
        label: "South Indian temples"
    },
    {
        term: "How to write a winning scholarship essay for Indian universities?",
        label: "Scholarship essay"
    },
    // {
    //     term: "Discuss the role of women in modern Indian society",
    //     label: "Role of women in India"
    // },
    {
        term: "What is the relevance of yoga in today's fast-paced world? Include global perspectives",
        label: "Relevance of yoga"
    },
    {
        term: "Provide tips for urban farming in Indian cities",
        label: "Urban farming"
    },
    {
        term: "Discuss the contribution of Raja Ram Mohan Roy in the Indian renaissance",
        label: "Raja Ram Mohan Roy"
    },
    {
        term: "How to tackle stress and anxiety during exams? Include references to Indian education",
        label: "Tips for tackling stress"
    },
    {
        term: "How to make authentic butter chicken? Add variations and vegan options",
        label: "How to make butter chicken"
    },
    {
        term: "Discuss the rise of e-commerce in India post-COVID-19",
        label: "E-commerce rise in India"
    },
    {
        term: "Write a template for business email",
        label: "Business email template"
    },
    {
        term: "Write a poem on India",
        label: "Poem on India"
    },
    {
        term: "List common phrases and slangs used by Indian millennials and their meanings",
        label: "Indian millennial slangs"
    },
    {
        term: "Give me ideas on how to plan my weekend",
        label: "Plan my weekend"
    },
    {
        term: "How to promote Indian art and culture globally?",
        label: "Promote Indian culture globally"
    },
    {
        term: "Recommend the top ten Indian podcasts of 2022",
        label: "Top Indian podcasts"
    },
    {
        term: "How to make Indian street style Maggi noodles?",
        label: "Indian street style Maggi"
    },
    {
        term: "Discuss the role of Indian youth in nation-building",
        label: "Indian youth in nation-building"
    },
    {
        term: "Provide an introduction to Indian folk music and its varieties",
        label: "Indian folk music intro"
    },
    {
        term: "How to build a startup in India? Include regulations, funding, and challenges",
        label: "How to build a Startup"
    },
    {
        term: "Discuss the factors leading to the rise of digital payments in India",
        label: "Rise of digital payments in India"
    },
    {
        term: "Recommend the best Indian web series of 2022",
        label: "Best Indian web series 2022"
    },
    {
        term: "List the types of traditional Indian embroideries and their features",
        label: "Indian embroideries types"
    },
    {
        term: "Discuss the impact of the Green Revolution on Indian agriculture",
        label: "Green Revolution's impact"
    },
    {
        term: "How to grow a startup in India? Include case studies",
        label: "startup"
    },
    {
        term: "What are the various types of Indian martial arts?",
        label: "Types of Indian martial arts"
    },
    {
        term: "Discuss the beauty of the Indian Himalayas and its significance",
        label: "Beauty of Indian Himalayas"
    },
    {
        term: "What are the best practices for organic farming in India?",
        label: "Organic farming best practices"
    },
    {
        term: "How to build a portfolio for architecture students in India?",
        label: "Build Architecture portfolio"
    },
    {
        term: "Provide tips to crack the CAT exam for MBA in India",
        label: "Tips to crack CAT exam"
    },
    {
        term: "Recommend the top Indian movies on Netflix",
        label: "Top Indian movies on Netflix"
    },
    {
        term: "Describe the traditions and rituals of an Indian wedding",
        label: "Indian wedding traditions"
    },
    {
        term: "How to prepare for the GATE exam in India?",
        label: "GATE exam preparation tips"
    },
    {
        term: "What are the top startups in India in 2022?",
        label: "Top startups in India 2022"
    },
    {
        term: "Recommend a list of top Indian influencers to follow on Instagram in 2023",
        label: "Top Indian Instagram influencers"
    },
    {
        term: "Discuss the role of the Indian judiciary in upholding democracy",
        label: "Role of Indian judiciary"
    },
    {
        term: "Provide a history of Indian currency and its evolution",
        label: "History of Indian currency"
    },
    {
        term: "Discuss the influence of Indian spices in global cuisine",
        label: "Indian spices in global cuisine"
    },
    {
        term: "Recommend the best Indian documentaries",
        label: "Documentaries"
    },
    {
        term: "Write a concise history of the Maratha Empire",
        label: "Maratha Empire"
    },
    {
        term: "How to create a podcast in India? Include equipment, platforms, and marketing",
        label: "How to create a podcast"
    },
    {
        term: "Discuss the features and benefits of the Indian PSLV rocket",
        label: "Indian PSLV features"
    },
  
]

export const modes = [
    "Ask AI anything..",
    // "Write a job description for a Senior Product Manager in ABC Corp. Industry experience should be more than 5 years",
    // "Next 5 day weather forecast in NYC",
    // "Create a detailed Resume for a Chef applying to a 5-star hotel",
    // "Ask AI any question",
    // "How can AI help me be more productive as a marketing professional?",
    // "Ask AI any question",
    // "Describe what you'd like to write",
    // "How can AI help me be more productive as an engineer?",
    // "Write a job description for a Senior Backend Engineer in ABC Corp. Industry experience should be more than 10 years",
    // "Ask AI any question",
]

export const placeholders = [
    "Ask AI any question",
    "Write a job application for me",
    "Write OOO email, sick leave",
    "Next 5 day weather forecast in Delhi",
    "Helpline number change railway booking",
    "fastest route from Delhi to Jaipur",
    "What will Chandrayaan 3 find?",
    "Top 5 highest paid actors in India",
    "When is India vs Pakistan next match?",
    "Peacock Photo",
    // "पासपोर्ट कैसे बनाएं",
    // "तेंदुआ की फोटो",
    "Ask AI for live news",
    "When is India vs Pakistan match in Asia Cup 2023?",
    "Ask AI any question",
    "Write OOO email, sick leave",
    "Write a job application",
    "When is India vs Pakistan match in Asia Cup 2023?",
    "Ask AI any question",
    // "चंद्रयान 3 की नवीनतम समाचार",
    "Smartphone options in ₹ 10,000 range",
    // "वोट कैसे दें",
    "Samsung Galaxy F04 vs Realme Narzo N53",
    "Xiaomi Redmi 12 vs Motorola Moto G14",
    "Smartphone options in ₹ 10,000 range",
    "Ask AI any question",
    // "Zerodha खाता कैसे खोलें?",
    // "₹10,000 रेंज में स्मार्टफोन विकल्प",
    // "हिमाचल प्रदेश में भूस्खलन समाचार",
    "Samsung Galaxy F04 vs Realme Narzo N53",
    "Xiaomi Redmi 12 vs Motorola Moto G14",
    "Samsung Galaxy F04 vs Realme Narzo N53",
    // "बिग बॉस OTT 2 का विजेता",
    // "इन्फोसिस लिमिटेड की स्टॉक मूल्य",
    "How to open DMAT account?",
    "How to update Aadhar card phone number?",
    "How to use Paytm?",
    "How to get Police Verification for passport?",
    "Generate 3 ideas for startups"
];
