import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

/**
 * Will appear for paid user
 */
export default function PaidCard({paymentInfo, meter, location}) {

    const bull = (
        <Box
          component="span"
          sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
          •
        </Box>
      );
  return (
    <Card sx={{ minWidth: 275, background:"whitesmoke", border:"none", boxShadow:"none" }}>
       
      <CardContent>
      <CardActions>
        
      </CardActions> 
        <Typography sx={{ fontSize: 30, textAlign:"center" }} color="text.primary" gutterBottom>
          WriteChat.ai
        </Typography>  
        
      
       {(!!paymentInfo && paymentInfo.payment_status == "paid") && <Button className="get_started" style={{margin:"auto"}} onClick={(_)=>location("/")}>Start using WriteChat</Button>}
      <br/>
      <br/>
       <Typography sx={{ fontSize: 25, textAlign:"center" }} color="text.primary" gutterBottom>
       {(!!paymentInfo && paymentInfo.payment_status == "paid") && <p>{meter}/5 generations used</p>}
        </Typography>  
       
        {/* <br /> */}
       <><br /></>
       {/* <Typography sx={{ fontSize: 11, textAlign:"center" }} variant="h5" component="div">
            *Usage Limit (5 generations, approx. 5 to 10 days of use)
        </Typography> */}
  
        <br/>
        <br/>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Developed by ex-Microsoft Copilot creators
        </Typography>
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} More than 40x cheaper than ChatGPT Plus!
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Cancel Anytime
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} 40 times cheaper than Jasper!
        </Typography> */}
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Customised AI for Writing prompts
        </Typography>
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Worlds most powerful AI 
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Grounded on Bing Search results
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} High quality Citations/References  
        </Typography> */}
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Control AI with Refinements 
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Make it Shorter, Simpler, Detailed  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Add emojis, Increase Font size    
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} AI Recommended refinements  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Copy or Share generations
        </Typography>  
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} AI generated Images (coming soon)
        </Typography>  
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} AI generated Stories (coming soon)
        </Typography>   */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} Custom AI for Writing and Refinements
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} AI Video tutorials 
        </Typography> */}
        {/* <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} (Coming Soon) File Upload  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} (Coming Soon) AI Powered Word Processor  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} (Coming Soon) End to End encryption  
        </Typography>
        <Typography sx={{textAlign:"left"}} variant="body1">
           {bull} (Coming Soon) New Monthly AI tutorials
        </Typography>     */}
      </CardContent>
      
      {/* <CardActions>
      <Button size="small" variant='outline'>How do we provide all this for less than a dollar?</Button>
      </CardActions> */}
    </Card>
  );
}
