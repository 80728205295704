import React, { useState, useEffect, useRef } from "react";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useStateValue } from '../statemanagement/StateProvider';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Docs.css";
import { Button } from "@mui/material";
import useAIService from "../helpers/useAIService";
import AtomicSpinner from 'atomic-spinner';
import Refine from "../components/AskFollowUp";
import { useNavigate } from "react-router-dom";
import Search from '../components/Search';
import CreateIcon from '@mui/icons-material/Create';
import ChatIcon from '@mui/icons-material/Chat';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { actionTypes } from '../statemanagement/reducer';

const Docs = () => {
    const [{ term, conversationHistory, followup, loading, streaming, chatMode }, dispatch] = useStateValue();
    const quillRef = useRef(null);
    useAIService(term, followup, null);
    const navigate = useNavigate();

    const [canvasContent, setCanvasContent] = useState(``);

    const wrapHtmlString = (html) => {
        return `&nbsp;\u200B${html}\u200B&nbsp;`; // Wrapping the HTML string between non-breaking and zero-width spaces
    };

    useEffect(() => {
        const displayConversationHistory = JSON.parse(JSON.stringify(conversationHistory));
        // console.log(JSON.stringify(displayConversationHistory));

        const updatedAssistantObject = displayConversationHistory.filter(u => u.role == "assistant").slice(-1)[0];
    
        if (!!updatedAssistantObject && !!updatedAssistantObject.content) {
            setCanvasContent(updatedAssistantObject.content);
         //   console.log("Email is streamed onto the docs");
        }else{
            if(displayConversationHistory.length == 1){
                setCanvasContent(displayConversationHistory[0].content); //user content 
            }else{
                setCanvasContent("");

            }
        }

        // updateState();
        // let canvasContent = "";
        // const separator = wrapHtmlString("<!--separator-->");
        // displayConversationHistory.filter(u=>u.role == "assistant").map(u=>{
        //     canvasContent+=(u.content+separator)
        // })
        // setCanvasContent(canvasContent);
        // console.log(canvasContent.split(separator));
       // autoscroll();//scroll to bottom



    }, [conversationHistory])

    const updateState = (conversationHistory)=> {

        const quillInstance = quillRef.current.getEditor();
        const content = quillInstance.root.innerHTML;

        const amendedHistory = conversationHistory.slice(0, -1);
        if (content.replace(/<[^>]*>/g, '').trim() !== "") { 
           
        //    console.log("There is content in quill");
          //  console.log(`Conversation history is: ${conversationHistory}`);

           if(conversationHistory.length == 0 || conversationHistory.length == 1 ){
            dispatch({
                type: actionTypes.UPDATE_CONVERSATION_HISTORY,
                enrichedConversationHistory: [{ "role": "user", "content": content }] // or derived data from it
              });
           }
           else{
            dispatch({type: actionTypes.UPDATE_CONVERSATION_HISTORY, enrichedConversationHistory:[...amendedHistory, {"role":"assistant","content":content}]})
           }
           

        } else{
        //    console.log("User has removed all data from quill");
          //  console.log("Reset State");
            dispatch({type:actionTypes.RESET_STATE});
           

            
        }
        //else{
            // dispatch({
            //     type: actionTypes.RESET_STATE
            //   });
            // dispatch({type:actionTypes.RESET_STATE})
        //}

        // dispatch({
        //     type: actionTypes.SET_STREAMING,
        //     streaming: true
        //   });
        // console.log(`Calling Update State`);
        // console.log("Update state");
        // const amendedHistory = conversationHistory.slice(0, -1);
        // console.log(`Canvas Content: ${canvasContent}`);
      
    }
    useEffect(() => {

        /**
         * dispatch({
                  type: actionTypes.UPDATE_CONVERSATION_HISTORY,
                  enrichedConversationHistory: [...conversationHistory, { "role": "assistant", "content": u }] // or derived data from it
                });
         */

     //   const amendedHistory = conversationHistory.slice(0, -1);
        // if (canvasContent.replace(/<[^>]*>/g, '').trim() !== "") {
        //     dispatch({
        //         type: actionTypes.UPDATE_CONVERSATION_HISTORY,
        //         enrichedConversationHistory: [...conversationHistory, { "role": "assistant", "content": canvasContent }] // or derived data from it
        //       });
        // }
        

        // console.log(`Canvas Content: ${canvasContent}`)
        // console.log(conversationHistory);
        // const lastOutput = conversationHistory.filter(u => u.role == "assistant").slice(-1)[0];
        // console.log(`Last Output`);
        // console.log(lastOutput);
        // console.log(`Canvas Content`);
        // console.log(canvasContent);
       
        // console.log(`Updated Last Output`);
        // console.log(lastOutput);

        // if (canvasContent.replace(/<[^>]*>/g, '').trim() === "") {
        //     console.log("Quill is effectively empty");
        //   } else {
        //     lastOutput.content = canvasContent;
        //   }

        //  console.log(canvasContent);
        // const separator = wrapHtmlString("<!--separator-->");
        //console.log(conversationHistory);
        // const displayConversationHistory = JSON.parse(JSON.stringify(conversationHistory)).filter(u=>u.role == "assistant");
        // const liveHistory = canvasContent.split("<p>&nbsp;​​&nbsp;</p>");

        // let c = 0;
        // conversationHistory.map((u,i)=>{
        //     if(u["role"] == "assistant" && !!liveHistory[c] && liveHistory[c] !=""){
        //         u["content"] = liveHistory[c];
        //         c++;
        //     }
        // })

        // console.log("Convo");

        // console.log(conversationHistory);

        // liveHistory.map((u,index)=>{
        //     //u is the content
        //     //index is the index
        //     displayConversationHistory[index] = u;
        // });
        // console.log(`convo`)
        // console.log(displayConversationHistory);

    }, [canvasContent])

    const autoscroll = () => {
        if (!!quillRef && !!quillRef.current) {
            const quill = quillRef.current.getEditor();
            const scrollContainer = quill.scrollingContainer;
            if (scrollContainer) {
                scrollContainer.scrollTop = scrollContainer.scrollHeight;
            }
        }

    }

    useEffect(() => {
        if (quillRef && quillRef.current) {
            const quill = quillRef.current.getEditor(); // get the Quill instance

            quill.on('text-change', function(delta, oldDelta, source) {
                if (source === 'user') {
                   // console.log('User begins editing');
                    dispatch({type:actionTypes.SET_EDITING, editing: true})
                    window.gtag('event', "Edit");
                    updateState(conversationHistory);
                    dispatch({type:actionTypes.SET_EDITING, editing: false})
                }
            });
        }

        // Remember to deregister the listener on unmount to avoid memory leaks
        return () => {
            if (quillRef && quillRef.current) {
                const quill = quillRef.current.getEditor();
                quill.off('text-change');
            }
        };
    }, [conversationHistory]);

    const modules = {
        toolbar: [
          [{ 'header': '1'},{ 'font': [] }, {size: []}],
        //   [{size: []}],
          ['bold', 'italic', 'underline', 'strike'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],                                  
        ],
      };

      const focusButtonStyle = () => {
        return {borderRadius: "18px", boxShadow:"2px 4px 12px rgba(0,0,0,.5)", background: "black", paddingLeft:"16px", opacity:"0.9", marginLeft:"5px",  marginRight:"5px", fontSize:"small", color:"white"};
      }

    return (
        <div className="resultsPage">
            <div className='resultsPage__header'>
                <div style={{ display: "flex", alignItems: "baseline" }} className='resultsPage__header__prompt__entry'>
                    <AllInclusiveIcon style={{ marginRight: "2.5%" }} onClick={(_) => {dispatch({type:actionTypes.RESET_STATE});; navigate("/")}} />
                    {!chatMode && <Search w={"97%"} placeholder={"Start New, Ask AI anything.."} showClear={true} />}
                    {chatMode && <h1 onClick={(_) => window.location.href = "https://chatdragon.ai/"} style={{ fontWeight: 500, opacity: "0.9", fontFamily: "'Roboto', sans-serif" }}>ChatDragon.ai</h1>}
                </div>
                <div style={{textAlign:"center"}}>
                 <Button disabled={streaming} startIcon={<ChatIcon />} style={{ color: "black" }} onClick={_ => { navigate("/query") }}>Chat</Button>
                <Button disabled={streaming} startIcon={<CreateIcon />} style={focusButtonStyle()} onClick={_ => { navigate("/docs") }}>Edit (Beta)</Button>
                <Button disabled={streaming} startIcon={<ClearAllIcon />} style={{color:"black"}} onClick={_ =>{ dispatch({type:actionTypes.RESET_STATE}); }}>Clear All</Button>
                </div>
            </div>
            <div className="resultsPage__body">
                {loading && <div className='ai__display'>
                    <div className='ai__display__assistant'> <div className='resultsPage__ai__response__spinner' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <AtomicSpinner style={{ width: '250px', height: '250px' }} />
                    </div></div></div>
                }
                {!loading && <div><ReactQuill modules={modules} ref={quillRef} value={canvasContent} onChange={(_) => setCanvasContent(_)} />
                    {/* <Button style={{
                    position:"absolute",
                    bottom:"10px",
                    right:"10px",
                    zIndex:1000
                }} >Continue Writing with AI
                </Button> */}
                </div>
                }


            </div>
            <div className='resultsPage__footer'>
                <Refine />
            </div>
        </div>
    );
}

export default Docs;
