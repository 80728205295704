import React from 'react';
import { useNavigate } from "react-router-dom"
import { useAuthWrapper } from './AuthWrapper';
import Header from './Header';
import Footer from './Footer';

const CustomerSupport = () => {

    const navigate = useNavigate();
    useAuthWrapper();

    return (
        <div>
            <div className="container">
                <Header showLogin={false} />
                <div style={{ marginTop: "2%" }}>
                    <div className="">
                        <div className="privacy-policy" style={{ marginTop: "7%" }}>
                            <h1 style={{fontWeight:400}}>Customer Support</h1>
                            <br />
                            <p><i>Last updated: Jan 23rd, 2024</i></p>
                            {/* <br /><br />
                            <h3>Our Services</h3>
                            <br />
                            <p style={{fontSize:"12px"}}>
                                ChatDragon offers a text generation service for writing assistance and question answering. ChatDragon can be accessed through{' '}
                                <a href="https://www.chatdragon.ai">https://www.chatdragon.ai</a> and its associated internet domains.
                            </p> */}
                            <br />
                            <h3>Privacy Policy</h3>
                            <br />
                            <p style={{fontSize:"12px"}}>
                                Our Privacy Policy can be accessed via{' '}
                                <a href="https://www.chatdragon.ai/privacy">https://www.chatdragon.ai/privacy</a> and its associated internet domains.
                            </p>
                            
                           
                            {/* Add more sections here... */}

                            <br />
                            <h3>Contact Us</h3>
                            <br />
                            <p style={{fontSize:"12px"}}>If you have any inquiries regarding this Policy or any other query, kindly reach out to us at <a href="mailto:support@chatdragon.ai">support@chatdragon.ai</a>.</p>
                            <br />
                      
                            <br />
                           
                            <br />
                        </div>
                        <br />

                    </div>
                    <br />

                </div>

            </div>
            <Footer/>
        </div>


    );
};

export default CustomerSupport;
